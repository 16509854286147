div.checkbox {
	position: relative;
	display: inline-block;
	margin-right: 1rem;
	white-space: nowrap;
	line-height: 1;
}

div.checkbox input {
	position: absolute;
	left: 0;
	top: 0;
	min-width: 1rem;
	width: 100%;
	height: 100%;
	z-index: 2;
	opacity: 0;
	margin: 0;
}

div.checkbox label {
	position: initial;
	display: inline-block;
	margin: 0;
	text-indent: 1.5rem;
	min-width: calc(1rem + 2px);
}

div.checkbox label span:before,
div.checkbox label span:after {
	content: "";
	width: calc(1em + 2px);
	height: calc(1em + 2px);
	display: block;
	border-radius: 3px;
	border: 1px solid #ccc;
	z-index: 0;
	position: absolute;
	left: 0;
	top: calc((0% - (100% - 1em)) - 8%);
	background-color: transparent;
}

div.checkbox.radio label span:before,
div.checkbox.radio label span:after {
	border-radius: 50px;
}

div.checkbox label span:after {
	border: 0;
	transform: scale(0.6);
}

div.checkbox input:checked ~ label span:after {
	background-color: var(--primary-color);
}
