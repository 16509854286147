.multi-form-container .multi-form-row:not(:last-child) {
	margin-bottom: 4px;
}

.multi-form-row {
	display: flex;
	align-items: center;
	height: 36px;
}

.multi-form-row .svg-container {
	height: 100%;
	width: 20px;
	display: flex;
	align-items: center;
	justify-content: center;
	cursor: pointer;
	margin-left: 0.3rem;
}

.multi-form-row svg line {
	fill: none;
	stroke: hsl(0, 0%, 50%);
	stroke-linecap: round;
	stroke-linejoin: round;
	stroke-width: 32px;
	transition: stroke 200ms ease;
}

.multi-form-row .svg-container:hover line {
	stroke: hsl(0, 0%, 20%);
}
