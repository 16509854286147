#Main {
	transition: grid-template-columns 150ms ease-in;
}

.zoomed {
	grid-template-columns: 0px 1fr;
}

/*
   Loading Indicator
*/
@keyframes shuffle {
	0% {
		stroke-dashoffset: 100;
		stroke-dasharray: 100;
	}

	50% {
		stroke-dasharray: 80;
	}

	100% {
		stroke-dashoffset: -100;
		stroke-dasharray: 100;
	}
}

main > svg {
	position: fixed;
	top: 50;
	left: 0;
	height: 5px;
	z-index: 1000;
	width: 100%;
	stroke: var(--carrot);

	stroke-dashoffset: -100;
	stroke-dasharray: 200;
	transition: all 1s ease-out;
}

main > svg.active {
	stroke-dashoffset: 0;
	stroke-dasharray: 100;
	transition: all 1s ease-out;
	animation: 1.4s ease-out infinite shuffle;
}

.unauthorized-page {
	height: calc(100vh - 50px);
	white-space: pre-line;
	display: flex;
	justify-content: center;
	align-items: center;
	font-family: "Signifier";
	font-style: normal;
	font-weight: 300;
	font-size: 32px;
	line-height: 41px;
}

.unauthorized-page a {
	text-decoration: underline;
	color: var(--fucales);
}
