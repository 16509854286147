.contextMenu {
	position: absolute;
	z-index: 99999;
	background-color: white;
	box-shadow: var(--box-shadow);
	user-select: none;
	min-width: 100px;
	max-height: 200px;
	overflow: auto;
}

.contextMenu div {
	padding: 5px 10px;
	cursor: pointer;
}

.contextMenu div:hover {
	background-color: var(--carrot-lighter);
}
