.Logo {
	display: grid;
	grid-template-columns: 50px 6em;
	font-size: 0.8rem;
	user-select: none;
	height: 50px;
	border-bottom: var(--border);
}

.Logo > * {
	margin-left: 1rem;
}

.logo-wrapper {
	border-radius: 50%;
	width: 40px;
	height: 40px;
	background-color: var(--lime);
	margin: auto;
	margin-left: 1rem;
}

.Logo img {
	align-self: center;
	height: 30px;
}

.Logo div {
	display: flex;
	flex-direction: column;
	justify-content: center;
	font-family: var(--font-family-primary);
	font-weight: 600;
	font-size: 16px;
}

.Logo span:nth-of-type(2) {
	color: var(--black);
	font-family: var(--font-family-secondary);
	font-size: 12px;
	font-weight: normal;
}
