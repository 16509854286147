.spinner {
	display: flex;
	justify-content: center;
	align-items: center;
}

@keyframes spinner-animation {
	0%,
	25% {
		stroke-dashoffset: 280;
		transform: rotate(0);
	}

	50%,
	75% {
		stroke-dashoffset: 75;
		transform: rotate(45deg);
	}

	100% {
		stroke-dashoffset: 280;
		transform: rotate(360deg);
	}
}

@keyframes svg-animation {
	0% {
		transform: rotateZ(0deg);
	}
	100% {
		transform: rotateZ(360deg);
	}
}

.spinner svg {
	max-width: 100px;
	animation: 2s linear infinite svg-animation;
}
.spinner circle {
	fill: transparent;
	stroke: var(--lime);
	stroke-width: 5px;
	stroke-linecap: round;
	stroke-dashoffset: 75;
	stroke-dasharray: 283;
	transform-origin: 50% 50%;
	animation: 1.4s ease-in-out infinite both spinner-animation;
}
