@font-face {
	font-family: "Signifier";
	src: url("./Signifier-Light.otf") format("opentype");
	font-weight: 300;
}

@font-face {
	font-family: "DM Sans";
	src: url("./DMSans-Regular.ttf") format("truetype");
	font-weight: 400;
	font-style: normal;
}

@font-face {
	font-family: "DM Sans";
	src: url("./DMSans-Italic.ttf") format("truetype");
	font-weight: 400;
	font-style: italic;
}

@font-face {
	font-family: "DM Sans";
	src: url("./DMSans-Medium.ttf") format("truetype");
	font-weight: 500;
	font-style: normal;
}

@font-face {
	font-family: "DM Sans";
	src: url("./DMSans-MediumItalic.ttf") format("truetype");
	font-weight: 500;
	font-style: italic;
}

@font-face {
	font-family: "DM Sans";
	src: url("./DMSans-Bold.ttf") format("truetype");
	font-weight: 600;
	font-style: normal;
}

@font-face {
	font-family: "DM Sans";
	src: url("./DMSans-BoldItalic.ttf") format("truetype");
	font-weight: 600;
	font-style: italic;
}
