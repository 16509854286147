.select-access-point-container {
	display: flex;
	width: 100%;
}

.select-access-point-container > div:nth-child(1) {
	flex: 1;
}
.select-access-point-container > div:nth-child(2) {
	width: 70px;
	margin-left: 0.2rem;
}
