.select {
	width: 100%;
	max-width: 300px;
	background-color: #fff;
}

.select .search {
	position: relative;
	border: var(--border2);
	height: 32px;
	padding-right: 0.5rem;
	margin: 0.5rem 0;
}

.select input {
	/* font: var(--font-family-secondary); */
	border: none;
	padding: 0 0.5rem;
	width: 100%;
	height: 30px;
	font-size: 16px;
}

.dropdown {
	width: 100%;
	max-width: 300px;
	max-height: 300px;
	position: absolute;
	border: var(--border2);
	font-size: 16px;
	background-color: white;
	z-index: 9999999999;
	overflow: auto;
	box-shadow: var(--box-shadow);
}

.dropdown .option {
	padding: 0.25rem 0.5rem;
	overflow: hidden;
	white-space: nowrap;
	text-overflow: ellipsis;
	cursor: pointer;
}

.dropdown .option:hover {
	background-color: var(--carrot-lighter);
}

.BasicSelect {
	padding: 0 0.5rem;
	height: 32px;
	width: 100%;
	font-family: "Barlow", sans-serif;
	font-size: 1rem;
	border: var(--border2);
}
