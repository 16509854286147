.datum {
	display: flex;
	flex-direction: column;
	margin-bottom: 2rem;
	margin-right: 2rem;
}

.datum > input[readonly] {
	color: #000;
	font-size: 1rem;
	font-weight: normal;
	background-color: transparent;
	border: 1px solid transparent;
	text-overflow: ellipsis;
}
.datum > input {
	color: #000;
	font-size: 1rem;
	font-weight: normal;
	background-color: transparent;
	border: 1px solid #ccc;
	padding: 0.5rem 0;
}
.datum.edit > input {
	padding: 0.5rem;
	width: 195px;
}

.datum-select {
	/* margin-top: 1rem; */
}

label.datum-select-label.text-input-field {
	border: 1px solid #ccc;
}
label.datum-select-label.text-input-field input {
	font-size: 1rem;
}
label.datum-select-label.text-input-field.readOnly {
	border: 1px solid transparent;
	height: initial;
}
label.datum-select-label.text-input-field.readOnly input {
	padding: 0;
	margin-top: 0.5rem;
}

.datum > .header {
	display: flex;
	align-items: center;
}
.datum > .header > span:nth-of-type(1) {
	/* margin-right: 1rem;
	font-weight: lighter;
	color: #aaa; */
	font: var(--font-label);
}
.datum > .header > svg {
	cursor: pointer;
}

.datum > .header > svg#pencil {
	fill: none;
	stroke: #000;
	stroke-opacity: 0.4;
	stroke-width: 32px;
	transition: stroke-opacity 300ms ease;
}
.datum > .header > svg#pencil:hover {
	stroke-opacity: 0.8;
}

.datum > .header > svg#save {
	margin-left: auto;
}
.datum > .header > svg#cross {
	margin-left: 0.5rem;
}

.datum > .header > svg#save,
.datum > .header > svg#cross {
	fill: #000;
	fill-opacity: 0.4;
	transition: fill-opacity 300ms ease;
}
.datum > .header > svg#save:hover,
.datum > .header > svg#cross:hover {
	fill-opacity: 0.8;
}
