#initapp {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-direction: column;
	height: 100vh;
	width: 100vw;
}

#initapp > .spinner > svg {
	max-width: 200px;
	height: 200px;
}

@keyframes pulse-text {
	0% {
		opacity: 1;
	}
	50% {
		opacity: 0.2;
	}
	100% {
		opacity: 1;
	}
}

@media print {
	body * {
		visibility: hidden !important;
	}

	.qr-code-container,
	.qr-code-container * {
		visibility: visible !important;
	}

	.qr-code-container {
		width: 100%;
		height: 100%;
		margin: 0;
		border: none;
	}

	.qr-print-wrapper {
		position: absolute;
		top: 0;
		left: 0;
		margin: 0;
		padding: 0;
	}
}

#initapp h1 {
	animation: 1.4s ease-in-out infinite pulse-text;
}
