.input-select {
	position: relative;
}

.input-select-results-container {
	background-color: #fff;
	z-index: 1;
	box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

	border: 1px solid hsl(0, 0%, 90%);
	position: absolute;
	/* width: 100%; */
	/* height: 100%; */
	top: 31px;
	font-size: 0.9rem;
	overflow: hidden;
	overflow-y: auto;
	max-height: 302px;
	cursor: default;
}

.input-select-results-container > div {
	font-size: 0.8rem;
	padding: 0.5rem;
}

.input-select-results-container > div.input-select-results:hover {
	background-color: #edf9f4;
}

.spinnerComp {
	position: absolute;
	width: 20px;
	left: 0.5rem;
	margin-top: 0.5rem;
	z-index: 1;
}
